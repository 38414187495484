import { Accordion, Flex, Heading, Text } from "@chakra-ui/react"
import { UpcomingBookingCard } from "UserCrowd/views/dashboard/UpcomingBookingCard"
import React from "react"
import { useListUsercrowdUpcomingBookings } from "~/api/generated/usabilityhub-components"

export const UpcomingBookingList: React.FC = () => {
  const { data, isLoading } = useListUsercrowdUpcomingBookings({})

  // For now, since this is not behind a feature flag, we don't want to show any kind of loading indicator.
  if (isLoading || !data || data.moderated_study_bookings.length === 0)
    return null

  let lastShownDay = ""

  return (
    <Flex direction="column">
      <Heading as="h2" fontSize="lg" fontWeight="medium" mb={4}>
        Your upcoming sessions
      </Heading>

      <Accordion
        allowToggle
        allowMultiple
        // Open all accordions by default
        defaultIndex={data.moderated_study_bookings.map((_, i) => i)}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        {data.moderated_study_bookings.map((booking) => {
          const day = new Date(booking.starts_at).toLocaleDateString(
            undefined,
            {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            }
          )

          const showDay = day !== lastShownDay
          lastShownDay = day

          return (
            <React.Fragment key={booking.moderated_study_application_id}>
              {showDay && (
                <Text color="text.primary" mb={-2}>
                  {day}
                </Text>
              )}
              <UpcomingBookingCard booking={booking} />
            </React.Fragment>
          )
        })}
      </Accordion>
    </Flex>
  )
}

import { Button, Text, useDisclosure } from "@chakra-ui/react"
import React from "react"

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "Shared/components/Card/Card"

import { DeleteTesterAccountModal } from "./DeleteTesterAccountModal"

type DeleteTesterAccount = React.FC<React.PropsWithChildren<unknown>>

export const DeleteTesterAccount: DeleteTesterAccount = () => {
  const {
    isOpen: isDeleteAccountModalOpen,
    onOpen: openDeleteAccountModal,
    onClose: closeDeleteAccountModal,
  } = useDisclosure()

  return (
    <>
      <Card>
        <CardHeader size="lg">Delete your account</CardHeader>
        <CardBody>
          <Text>
            Deleting your account is permanent and can{"\u2019"}t be undone.
            Your personal information will be erased.
          </Text>
          <CardFooter>
            <Button onClick={openDeleteAccountModal} colorScheme="red">
              Delete account
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
      <DeleteTesterAccountModal
        isOpen={isDeleteAccountModalOpen}
        onClose={closeDeleteAccountModal}
      />
    </>
  )
}

import { ComponentWithAs, Icon, IconProps } from "@chakra-ui/react"
import { BankNote01OutlineIcon } from "Shared/icons/untitled-ui/BankNote01OutlineIcon"
import { Briefcase01OutlineIcon } from "Shared/icons/untitled-ui/Briefcase01OutlineIcon"
import { Car02OutlineIcon } from "Shared/icons/untitled-ui/Car02OutlineIcon"
import { HelpCircleOutlineIcon } from "Shared/icons/untitled-ui/HelpCircleOutlineIcon"
import { Monitor01OutlineIcon } from "Shared/icons/untitled-ui/Monitor01OutlineIcon"
import { ShoppingBag01OutlineIcon } from "Shared/icons/untitled-ui/ShoppingBag01OutlineIcon"
import { camelCase } from "lodash"
import React, { useEffect } from "react"

type Props = {
  groupName: string
}

const ICONS: { [key: string]: ComponentWithAs<"svg", IconProps> } = {
  general: HelpCircleOutlineIcon,
  industryAndOccupation: Briefcase01OutlineIcon,
  financialProducts: BankNote01OutlineIcon,
  shopping: ShoppingBag01OutlineIcon,
  technology: Monitor01OutlineIcon,
  hobbiesAndInterests: Car02OutlineIcon,
}

const FALLBACK_ICON = HelpCircleOutlineIcon

export const DemographicAttributeGroupIcon: React.FC<Props> = ({
  groupName,
}) => {
  const camelCasedGroupName = camelCase(groupName)

  useEffect(() => {
    if (!(camelCasedGroupName in ICONS)) {
      reportError(
        new Error(
          `Could not find icon for demographic attribute group "${groupName}"; using fallback icon instead.`
        )
      )
    }
  }, [groupName])

  const icon =
    camelCasedGroupName in ICONS ? ICONS[camelCasedGroupName] : FALLBACK_ICON

  return <Icon as={icon} boxSize={4} color="ds.icon.subtle" />
}

import { Heading } from "@chakra-ui/react"
import React, { ReactNode } from "react"
import { Card } from "./TestersCard"

interface EmptyListPlaceholderProps {
  heading: string
  children: ReactNode
}

export const EmptyListPlaceholder: React.FC<
  React.PropsWithChildren<EmptyListPlaceholderProps>
> = ({ heading, children }) => {
  return (
    <Card
      display="flex"
      flexDirection="column"
      alignItems="center"
      borderStyle="dashed"
      borderWidth={2}
      h="fit-content"
      p={7}
    >
      <Heading as="h3" fontSize="lg" fontWeight="medium">
        {heading}
      </Heading>
      {children}
    </Card>
  )
}

import { useToast } from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { isBadRequestError } from "Services/axios"
import * as PanelistNotifications from "Services/panelist-notifications"
import { throwIfNonAxiosError } from "Utilities/error"
import { useEffect, useState } from "react"
import { useListNotificationSubscriptions } from "~/api/generated/usabilityhub-components"

export const usePushNotifications = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const [isInitialized, setIsInitialized] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [currentEndpoint, setCurrentEndpoint] = useState<string | null>(null)

  const isSupported = PanelistNotifications.ARE_NOTIFICATIONS_SUPPORTED

  const { data: subscriptions } = useListNotificationSubscriptions({})

  useEffect(() => {
    if (isInitialized || !isSupported || !subscriptions) return

    setIsInitialized(true)

    const init = async () => {
      const pushSubscription = await PanelistNotifications.getPushSubscription()

      if (!pushSubscription) return

      setCurrentEndpoint(pushSubscription.endpoint)

      const activeSubscription = subscriptions.notification_subscriptions.find(
        (s) => s.endpoint === pushSubscription.endpoint
      )

      // The browser thinks it is subscribed but we had no record of it.
      if (!activeSubscription) {
        try {
          // Try silently enabling notification since there may have been an error
          // posting the original subscription info to our servers
          await PanelistNotifications.subscribe()
        } catch (error) {
          if (isBadRequestError(error)) {
            // If there was an error it’s probably because another user has already
            // subscribed on this browser which we handle as a 400 error, ignore
            // this since we can only have one registered push notification per browser
            return
          } else {
            // Rethrow an unexpected error.
            throwIfNonAxiosError(error)
          }
        }
      }
    }

    init()
  }, [isInitialized, subscriptions])

  const enableNotifications = async () => {
    if (!isSupported) return

    setIsBusy(true)

    try {
      const subscription = await PanelistNotifications.subscribe()
      setCurrentEndpoint(subscription.endpoint)
      await queryClient.invalidateQueries(
        ["api", "usercrowd", "notification_subscriptions"],
        { exact: true }
      )

      PanelistNotifications.showNotification("Notifications enabled", {
        body: "You will now see a notification like this when a test is available!",
      })
    } catch (error) {
      if (error instanceof PanelistNotifications.PermissionDeniedError) {
        toast({
          status: "error",
          title:
            "Could not enable notifications because permission was not granted",
        })
      } else if (isBadRequestError(error)) {
        toast({
          status: "error",
          title: error.response.data.message,
        })
      } else {
        // This is an unexpected error.
        reportError(error)
        toast({
          status: "error",
          title: "Unable to turn on notifications",
        })
      }
    } finally {
      setIsBusy(false)
    }
  }

  const disableNotifications = async () => {
    if (!isSupported || !subscriptions) return

    setIsBusy(true)

    try {
      const getSubscriptionByEndpoint = (endpoint: string) => {
        return (
          subscriptions.notification_subscriptions.find(
            (s) => s.endpoint === endpoint
          ) ?? null
        )
      }

      const subscription = await PanelistNotifications.unsubscribe(
        getSubscriptionByEndpoint
      )
      await queryClient.invalidateQueries(
        ["api", "usercrowd", "notification_subscriptions"],
        { exact: true }
      )

      if (subscription === null) return

      toast({
        status: "success",
        title:
          "You will no longer receive notifications when tests are available",
      })
    } catch (error) {
      toast({
        status: "error",
        title: "Sorry, something went wrong. Try reloading the page.",
      })
    } finally {
      setIsBusy(false)
    }
  }

  const activeSubscription =
    currentEndpoint &&
    subscriptions?.notification_subscriptions.find(
      (s) => s.endpoint === currentEndpoint
    )

  return {
    isInitialized,
    isEnabled: !!activeSubscription,
    isBusy,
    setEnabled: (enabled: boolean) => {
      enabled ? enableNotifications() : disableNotifications()
    },
  }
}

import {
  Box,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Link,
  Spinner,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react"
import { BellIcon, ExclamationIcon, MailIcon } from "@heroicons/react/outline"
import React, { ChangeEvent } from "react"

import { useQueryClient } from "@tanstack/react-query"
import Constants from "Constants/shared.json"
import { ARE_NOTIFICATIONS_SUPPORTED } from "Services/panelist-notifications"
import {
  Card,
  CardBody,
  CardGroup,
  CardHeader,
} from "Shared/components/Card/Card"
import {
  InterviewsStudyCompositeIcon,
  UsabilityTestCompositeIcon,
} from "Shared/components/CompositeIcons/CompositeIcons"
import { usePushNotifications } from "Shared/hooks/usePushNotifications"
import {
  GetPanelistNotificationPreferencesResponse,
  UpdatePanelistNotificationPreferenceVariables,
  useGetPanelistNotificationPreferences,
  useGetPanelistSettings,
  useUpdatePanelistNotificationPreference,
  useUpdatePanelistSettings,
} from "~/api/generated/usabilityhub-components"
import { PanelistAvailability } from "./PanelistAvailability"

export const TestersNotificationsForm: React.FC = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  const { isInitialized, isEnabled, isBusy, setEnabled } =
    usePushNotifications()

  const { data: settings } = useGetPanelistSettings({})

  const { mutate: saveSettings } = useUpdatePanelistSettings({
    onMutate: (variables) => {
      queryClient.cancelQueries(["api", "usercrowd", "panelist", "settings"])
      // Optimistic update to make the toggle feel snappy
      queryClient.setQueryData(
        ["api", "usercrowd", "panelist", "settings"],
        variables.body
      )
    },
    onSuccess: () => {
      toast({ title: "Changes saved", status: "success" })
      return queryClient.invalidateQueries([
        "api",
        "usercrowd",
        "panelist",
        "settings",
      ])
    },
    onError: () => {
      toast({
        title: "Failed to save changes. Please try again.",
        status: "error",
      })
    },
  })

  if (!settings) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    saveSettings({
      body: {
        last_dismissed_timezone_suggestion:
          settings.last_dismissed_timezone_suggestion,
        notify_assignments_by_email: event.target.checked,
      },
    })
  }

  const handleDismissTimezoneRecommendation = (recommendedTimezone: string) => {
    saveSettings({
      body: {
        ...settings,
        last_dismissed_timezone_suggestion: recommendedTimezone,
      },
    })
  }

  const NotificationIcon = ARE_NOTIFICATIONS_SUPPORTED
    ? BellIcon
    : ExclamationIcon

  return (
    <CardGroup>
      <NotificationTogglesForm />
      <Card>
        <CardHeader size="lg">How would you like to be notified?</CardHeader>
        <Stack direction="row" spacing={4} flexWrap="wrap">
          <CardBody
            flexBasis="300px"
            flexGrow={1}
            bg={ARE_NOTIFICATIONS_SUPPORTED ? "white" : "brand.primary.50"}
            borderColor={
              ARE_NOTIFICATIONS_SUPPORTED ? "gray.200" : "brand.primary.100"
            }
            overflow="hidden"
          >
            <FormControl>
              <Flex justify="space-between" mb={2}>
                <FormLabel
                  htmlFor="push-notifications"
                  display="inline-flex"
                  alignItems="center"
                >
                  <Icon
                    as={NotificationIcon}
                    width="24px"
                    height="24px"
                    mr={3}
                    color={
                      ARE_NOTIFICATIONS_SUPPORTED
                        ? "gray.500"
                        : "brand.primary.500"
                    }
                  />
                  Push notifications
                </FormLabel>
                <Switch
                  id="push-notifications"
                  onChange={() => setEnabled(!isEnabled)}
                  isChecked={isEnabled}
                  isDisabled={
                    !ARE_NOTIFICATIONS_SUPPORTED || !isInitialized || isBusy
                  }
                />
              </Flex>
              {ARE_NOTIFICATIONS_SUPPORTED ? (
                <Text fontSize="md">
                  Get alerts for new tests in your browser, even when UserCrowd
                  is closed.
                </Text>
              ) : (
                <Text fontSize="md">
                  Your browser does not support push notifications, check your
                  settings and the{" "}
                  <Link
                    href="https://caniuse.com/#feat=push-api"
                    textDecoration="underline"
                  >
                    browser compatibility table
                  </Link>
                  .
                </Text>
              )}
            </FormControl>
          </CardBody>
          <CardBody flexBasis="300px" flexGrow={1}>
            <FormControl>
              <Flex justify="space-between" mb={2}>
                <FormLabel
                  htmlFor="email-notifications"
                  display="inline-flex"
                  alignItems="center"
                >
                  <Icon
                    as={MailIcon}
                    width="24px"
                    height="24px"
                    mr={3}
                    color="gray.500"
                  />
                  Email notifications
                </FormLabel>
                <Switch
                  id="email-notifications"
                  onChange={handleEmailChange}
                  isChecked={settings.notify_assignments_by_email}
                />
              </Flex>
              <Text fontSize="md">
                Get an email whenever a new test becomes available.
              </Text>
            </FormControl>
          </CardBody>
        </Stack>
      </Card>
      <PanelistAvailability
        lastDismissed={settings.last_dismissed_timezone_suggestion}
        handleDismissTimezoneRecommendation={
          handleDismissTimezoneRecommendation
        }
      />
    </CardGroup>
  )
}

const queryKey = ["api", "usercrowd", "notification_preferences"]

const NotificationTogglesForm: React.FC = () => {
  const queryClient = useQueryClient()
  const {
    data: { notification_preferences: notificationPreferences } = {},
  } = useGetPanelistNotificationPreferences({})

  const hasEnabled = Object.fromEntries(
    notificationPreferences?.map(({ type, enabled }) => [type, enabled]) ?? []
  )

  const { mutate: updatePanelistNotificationPreference } =
    useUpdatePanelistNotificationPreference({
      // this does an optimistic update without having to track state in the
      // component and allows us to have smooth visual feedback on toggles
      // without the lag
      onMutate: async (newData) => {
        const newValue = newData.body
        await queryClient.cancelQueries({ queryKey })
        const previousValue = queryClient.getQueryData(queryKey)
        queryClient.setQueryData(
          queryKey,
          (old: GetPanelistNotificationPreferencesResponse) => ({
            ...old,
            notification_preferences: old.notification_preferences.map((n) =>
              n.type === newValue.type ? newValue : n
            ),
          })
        )
        return { previousValue }
      },
      onError: (
        _err,
        _newValue,
        context:
          | { previousValue: GetPanelistNotificationPreferencesResponse }
          | undefined
      ) => {
        queryClient.setQueryData(queryKey, context?.previousValue)
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    })

  const toast = useToast()

  const handleUpdateNotifications = (
    type: UpdatePanelistNotificationPreferenceVariables["body"]["type"],
    enabled: boolean
  ) => {
    updatePanelistNotificationPreference(
      { body: { type, enabled } },
      {
        onSuccess: () => {
          toast({
            title: "Notification preferences updated",
            status: "success",
          })
        },
        onError: () => {
          toast({
            title: "Error",
            description:
              "Failed to updated notification preferences. Please try again.",
            status: "error",
          })
        },
      }
    )
  }

  return (
    <Card>
      <CardHeader size="lg">
        What would you like to be notified about?
      </CardHeader>
      <CardBody>
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr auto"
          gap={4}
          alignItems="center"
        >
          <UsabilityTestCompositeIcon />
          <Flex direction="column" grow={1}>
            <Heading fontSize="md" fontWeight="semibold">
              Tests
            </Heading>
            <Text>
              You will be asked to complete a series of tasks and answer
              questions.{" "}
              <Link
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
                href={Constants.PANELIST_HELP_TEST_TAKING_GUIDELINES_URL}
                rel="noopener noreferer"
                isExternal
              >
                Learn more
              </Link>
            </Text>
          </Flex>
          <Flex align="center">
            <Switch
              isChecked={hasEnabled.usability_tests}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleUpdateNotifications(
                  "usability_tests",
                  event.target.checked
                )
              }
            />
          </Flex>
          {hasEnabled.usability_tests && (
            <Box
              bgColor="ds.surface.sunken"
              borderRadius="12"
              p={4}
              gridColumn="2 / -1"
            >
              <Checkbox
                isChecked={hasEnabled.usability_test_recordings}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleUpdateNotifications(
                    "usability_test_recordings",
                    event.target.checked
                  )
                }
              >
                Include tests with recording requirements
              </Checkbox>
            </Box>
          )}
        </Box>
        <Divider />
        <Flex gap={4}>
          <Flex align="center">
            <InterviewsStudyCompositeIcon />
          </Flex>
          <Flex direction="column" grow={1}>
            <Heading fontSize="md" fontWeight="semibold" position="relative">
              Interviews
            </Heading>
            <Text>
              You will be asked to attend online face-to-face meetings.{" "}
              <Link
                textDecoration="none"
                _hover={{ textDecoration: "none" }}
                href={Constants.PANELIST_INTERVIEWS_FAQ_URL}
                rel="noopener noreferer"
                isExternal
              >
                Learn more
              </Link>
            </Text>
          </Flex>
          <Flex align="center">
            <Switch
              isChecked={hasEnabled.moderated_studies}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleUpdateNotifications(
                  "moderated_studies",
                  event.target.checked
                )
              }
            />
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  )
}
